<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <CustomerSearch
          v-model="searchParams.customer"
          clearable
          @input="setCustomer($event)"
        />
      </v-col>
      <v-col v-if="this.$acl.isClinic()" cols="12" md="3">
        <SelectProfessional
          v-model="searchParams.professional"
          clearable
          :disabled="this.$acl.forceFilterOwnTransactions()"
          @input="setProfessional($event), select()"
        />
      </v-col>

      <v-col cols="6" md="2">
        <app-date-picker
          @input="select()"
          v-model="searchParams.date_start"
          label="Data Inicial"
        />
      </v-col>
      <v-col cols="6" md="2">
        <app-date-picker
          @input="select()"
          v-model="searchParams.date_end"
          label="Data Final"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="order-2 order-md-1">
        <template v-if="!$vuetify.breakpoint.mobile">
          <v-data-table
            :headers="headers"
            :items="transactions.data"
            disable-sort
            mobile-breakpoint="0"
            :items-per-page="-1"
            hide-default-footer
            @click:row="handleViewTransaction($event.customer.id)"
          >
            <template v-slot:[`item.description`]="{ item }">
              <CustomerLabel :customer="item.customer" />
            </template>
            <template v-slot:[`item.total_receivable`]="{ item }">
              {{ $format.moneyBr(item.total_receivable) }}
            </template>
          </v-data-table>
        </template>
        <template v-if="$vuetify.breakpoint.mobile">
          <v-list dense flat>
            <template>
              <v-list-item-group>
                <template v-for="(item, index) of transactions.data">
                  <v-list-item
                    @click.stop="handleViewTransaction(item.customer.id)"
                    class="px-0 my-0 align-center"
                    :key="index"
                  >
                    <v-list-item-content>
                      <div class="d-flex align-center">
                        <div class="mr-3">
                          <CustomerAvatar
                            size="30px"
                            :name="item.customer.name"
                          />
                        </div>
                        <div>
                          {{ item.customer.name }}
                        </div>
                      </div>
                    </v-list-item-content>
                    <v-list-item-action class="mr-3" @click.stop>
                      <span :class="['d-inline']">
                        {{ $format.decimal(item.total_receivable) }}
                      </span>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </template>
          </v-list>
        </template>
      </v-col>
      <v-col class="order-1 order-md-2" cols="12" md="3">
        <v-card color="grey lighten-3 " flat>
          <v-card-text>
            <div class="d-flex align-end justify-space-between mt-2">
              <h1>Total</h1>
              <h1 class="mt-3">{{ $format.decimal(net_total) }}</h1>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <PendingTransactionsView
      ref="PendingTransactionsView"
      :searchParams="searchParams"
    />
  </div>
</template>

<script>
import CustomerLabel from "@/components/customers/sections/CustomerLabel.vue";
import PendingTransactionsView from "@/components/transactions/sections/PendingTransactionsView.vue";
import CustomerAvatar from "@/components/customers/sections/CustomerAvatar.vue";
import CustomerSearch from "@/components/customers/ui/CustomerSearch";
import SelectProfessional from "@/components/app/forms/SelectProfessional.vue";
export default {
  components: {
    CustomerLabel,
    PendingTransactionsView,
    CustomerAvatar,
    CustomerSearch,
    SelectProfessional,
  },
  data() {
    return {
      transactions: {
        data: [],
      },
      net_total: 0,
      headers: [
        { text: "Nome", align: "start", value: "description" },

        {
          value: "total_receivable",

          text: "Valor devido",
          align: "center",
        },
      ],

      searchParams: {
        status: ["pending"],
      },

      icons: {
        custom: {
          in: "mdi-plus",
          out: "mdi-minus",
        },
        session: {
          in: "mdi-plus",
        },
        sessionGroup: {
          in: "mdi-plus",
        },
      },

      typeColor: {
        in: "success",
        out: "error",
      },
    };
  },

  mounted() {
    this.select();
  },

  methods: {
    select() {
      this.$loading.start();
      this.processPermission();

      this.$http
        .index("transactions/pending-transactions", this.searchParams)
        .then((response) => {
          this.transactions = response.transactions;
          this.net_total = response.net_total;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    processPermission() {
      if (this.$acl.forceFilterOwnTransactions()) {
        this.setProfessional(this.user);
      }
    },
    setProfessional(professional) {
      if (professional) {
        this.searchParams.professional = {
          id: professional.id,
          name: professional.name,
        };
        this.searchParams.professional_id = professional.id;
      }

      if (!professional) {
        this.searchParams.professional = null;
        this.searchParams.professional_id = null;
      }
    },

    setCustomer(customer) {
      if (customer) {
        this.searchParams.customer = {
          id: customer.id,
          name: customer.name,
        };
        this.searchParams.customer_id = customer.id;
      }

      if (!customer) {
        this.searchParams.customer = null;
        this.searchParams.customer_id = null;
      }

      this.select();
    },
    handleSearchInput(text) {
      this.searchParams.search = text;
      this.select();
    },
    handleViewTransaction(customer_id) {
      this.$refs.PendingTransactionsView.open(customer_id);
    },
  },
};
</script>

<style>
</style>