<template>
  <v-dialog
    persistent
    scrollable
    width="700"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div>
          <h4>Transações pendentes</h4>

          {{ totalPending }}
        </div>

        <div class="d-flex align-center">
          <div class="pr-3">
            <v-avatar size="40px" color="#E6F2E6">
              <app-icon color="success">attach_money</app-icon>
            </v-avatar>
          </div>
          <div>
            <small class="text--secondary">Total</small>
            <h4>{{ $format.decimal(sum(incomeUnpaid)) }}</h4>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <MobileTransactionsList
          :transactions="transactions"
          @show="openTransactionForm($event)"
        />
      </v-card-text>
      <v-card-actions id="form-card-footer" class="mt-0 pa-4">
        <v-btn @click="dialog = false" text class="mr-2"> Voltar </v-btn>
      </v-card-actions>
    </v-card>
    <TransactionForm @store="select()" ref="TransactionForm" />
  </v-dialog>
</template>

<script>
import MobileTransactionsList from "@/components/transactions/lists/MobileTransactionsList.vue";
import TransactionForm from "@/components/transactions/form/TransactionForm.vue";
import MobileTransactionStats from "@/components/transactions/sections/MobileTransactionStats.vue";

export default {
  components: {
    MobileTransactionsList,
    TransactionForm,
    MobileTransactionStats,
  },

  props: {
    searchParams: {},
  },

  data() {
    return {
      dialog: false,

      transactions: {
        data: [],
      },
      transactionsSum: [],
      balance: {},
      headers: [
        { text: "Nome", align: "start", value: "description" },

        {
          value: "value",

          text: "Valor devido",
          align: "center",
        },
      ],
      incomeUnpaid: (row) => row.type == "in" && row.status == "pending",
    };
  },

  computed: {
    totalPending() {
      return this.transactionsSum.reduce((sum, row) => {
        return sum + parseFloat(row.value_sum);
      }, 0)[0];
    },
  },
  methods: {
    open(customer_id) {
      this.dialog = true;
      this.searchParams.customer_id = customer_id;
      this.select();
    },
    sum(func) {
      return this.transactionsSum.reduce((sum, row) => {
        if (func(row)) {
          return sum + parseFloat(row.value_sum);
        }

        return sum;
      }, 0);
    },

    select() {
      this.$loading.start();
      this.$http
        .index("transactions/transactions", this.searchParams)
        .then((response) => {
          this.transactions = response.transactions;
          this.transactionsSum = response.transactionsSum;
          this.balance = response.balance;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    openTransactionForm(transaction) {
      var professional_id = transaction.session_group
        ? transaction.session_group.professional_id
        : null;

      if (this.$acl.canUpdateTransaction(professional_id)) {
        this.$refs.TransactionForm.open(transaction.id);
      }
    },
  },
};
</script>

<style>
</style>